import { usePathname } from 'next/navigation';
import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import useUIStore from '~/state/ui';
import customBreakpoints from '~/styles/theme/customBreakpoints.module.css';
import { getSpacer } from '~/utils';
import ScrollPosition from '~/utils/domEvents/ScrollPosition/ScrollPosition';
import { getNumberFromString } from '~/utils/Strings';
import { tickerAddOnce } from '~/utils/ticker';

import { ForwardedNavGroupRef } from '../NavGroup/NavGroup.types';
import useNavState from '../Navigation.state';
import { NavigationNavChildProps, NavigationState } from '../Navigation.types';
import useBanner from './useBanner';
import useMainNavActiveItemDelay from './useMainNavActiveItemDelay';
import useNavScroll from './useNavScroll';

const UIStore = useUIStore;

const getElBounds = ($el: HTMLDivElement | null) => {
  return $el?.getBoundingClientRect();
};

// get breakpoint from custom breakpoints defined in css
const navShowDesktop = getNumberFromString(customBreakpoints.navShowDesktop);

const useNavBar = ({
  navGroups,
  mobileNavGroups,
}: {
  navGroups: MutableRefObject<Record<string, ForwardedNavGroupRef>>;
  mobileNavGroups: MutableRefObject<Record<string, ForwardedNavGroupRef>>;
}) => {
  const pathname = usePathname();
  const [
    //states
    navigationData,
    activeMainNavItem,
    currentOpenedSubNav,
    currentPageMainNavId,
    currentPageSubNavId,
    isHoveringMainNavItem,
    previousSubNav,
    nextSubNav,

    //refs
    $el,
    $mainNavEl,
    $bannerEl,
    setCurrentGroup,
    setIsMobileNav,
    isMobileNav,

    //methods
    setCurrentPageMainNavId,
    setCurrentPageSubNavId,
    setActiveSubNavItem,
  ] = useNavState(
    (state) => [
      state.navigationData,
      state.activeMainNavItem,
      state.currentOpenedSubNav,
      state.currentPageMainNavId,
      state.currentPageSubNavId,
      state.isHoveringMainNavItem,
      state.previousSubNav,
      state.nextSubNav,
      state.$el,
      state.$mainNavEl,
      state.$bannerEl,
      state.setCurrentGroup,
      state.setIsMobileNav,
      state.isMobileNav,
      state.setCurrentPageMainNavId,
      state.setCurrentPageSubNavId,
      state.setActiveSubNavItem,
    ],
    shallow,
  );

  const navShowDesktopBktp = navShowDesktop;
  const windowWidth = useUIStore((state) => state.windowWidth);
  const windowHeight = useUIStore((state) => state.windowHeight);
  const setNavigationHeight = useUIStore((state) => state.setNavigationHeight);
  const setScrollPaddingTop = useUIStore((state) => state.setScrollPaddingTop);
  const initialVars: NavigationState = {
    breakpoint: UIStore.getState().breakpoint,
    mainNav: {
      triggerStart: 0,
      triggerEnd: 0,
      height: 0,
      distanceToBanner: 0,
    },

    banner: {
      triggerStart: 0,
      triggerEnd: 0,
      height: 0,
    },
    // how many pixels scrolling back up to trigger nav to reappear
    maxScrollUpDiff: 0,
    navOffset: 0,
    hasBlur: true,
  };

  const vars = useRef(initialVars);

  //   Check whether or not we scrolled past the Sub Nav
  function isPastMainNav() {
    const scrollY = ScrollPosition.y || 0;
    return scrollY > vars.current.mainNav.triggerEnd;
  }

  // custom hooks that handles differents functionalities of the nav:
  // - hook that handles the main navigation active state
  const {
    setActiveMainNavItemImmediately,
    setActiveMainNavItemToCurrentPageImmediately,
    setIsHoveringMainNavItemImmediately,
    setIsHoveringMainNavItemWithDelay,
    clearMainNavActiveStateDelay,
  } = useMainNavActiveItemDelay();
  // - hook that handles the banner
  const { bannerDismissed, setBannerAnimation, dismissBanner } = useBanner();

  // - hook that handles the scroll listening
  useNavScroll($el, vars.current, { isPastMainNav });

  // get current page main nav child id
  useEffect(() => {
    let mainNavId = null;
    let subNavId = null;

    const currentPath = pathname?.substr(pathname?.indexOf('/') + 1);

    navigationData?.primaryLinks?.forEach(
      (mainNavItem: NavigationNavChildProps) => {
        if (mainNavItem._type === 'link' && mainNavItem.url === pathname) {
          mainNavId = mainNavItem._key;
          return;
        } else if (mainNavItem._type === 'navGroup') {
          if (mainNavItem.groupItemLinks.includes(currentPath)) {
            mainNavId = mainNavItem._key;

            mainNavItem.group?.forEach((navGroup) => {
              navGroup.subcategoryLinks.forEach((link) => {
                if (link._type === 'link' && link.url === pathname) {
                  subNavId = link._key;
                  return;
                }
              });
            });
          }
        } else {
          return;
        }
      },
    );
    setCurrentPageMainNavId(mainNavId);
    setCurrentPageSubNavId(subNavId);
  }, [
    pathname,
    navigationData,
    setCurrentPageMainNavId,
    setCurrentPageSubNavId,
  ]);

  // On main nav change, make sure subnav is matching current subnavId if there is any
  useEffect(() => {
    setActiveMainNavItemToCurrentPageImmediately();
    setActiveSubNavItem(currentPageSubNavId);
  }, [
    setActiveMainNavItemToCurrentPageImmediately,
    currentPageMainNavId,
    setActiveSubNavItem,
    currentPageSubNavId,
  ]);

  useEffect(() => {
    if (!isMobileNav) {
      if (previousSubNav) {
        navGroups.current[previousSubNav].hidePreviousSubNavLinks();
      }

      if (nextSubNav) {
        navGroups.current[nextSubNav].showNextSubNavLinks();
      }
    }
  }, [previousSubNav, nextSubNav, isMobileNav, navGroups]);

  const showOrHideSubNav = (id: string) => {
    if (isMobileNav) {
      mobileNavGroups.current[id].openSubNav();
    } else {
      navGroups.current[id].showOrHideSubNav();
    }
  };

  const onClickMainNavGroup = (id: string) => {
    showOrHideSubNav(id);
  };

  const onMouseOverMainNavItem = useCallback(
    (id: string | undefined) => {
      if (isMobileNav || !id) return;
      // set the active state on the item currently being hovered
      setActiveMainNavItemImmediately(id);

      // set the is hovering boolean to deactive the non hovered items
      setIsHoveringMainNavItemImmediately(true);

      //if there is a subnav already open (via click), swap the currently links being displayed
      if (currentOpenedSubNav) {
        if (currentOpenedSubNav !== id) {
          if (navGroups.current[id]) navGroups.current[id].swapSubNav();
        }
      }
    },
    [
      setActiveMainNavItemImmediately,
      isMobileNav,
      setIsHoveringMainNavItemImmediately,
      currentOpenedSubNav,
      navGroups,
    ],
  );

  const onMouseOutMainNavItem = useCallback(
    // force is used to reset the active state if the group doesn't have a subnav
    (force = false) => {
      if (isMobileNav) return;

      //if there is no currently open subnav, set the active state back to the current page and reset the boolean to false to reactive the unhovered items
      if (!currentOpenedSubNav || force) {
        setIsHoveringMainNavItemWithDelay(false);
      }
    },
    [
      isMobileNav,
      currentOpenedSubNav,
      //setActiveMainNavItemWithDelay,
      setIsHoveringMainNavItemWithDelay,
    ],
  );

  //   get sizes and calculate trigger values, on resize
  useEffect(() => {
    // ticker only fires once
    if ($mainNavEl && $bannerEl) {
      tickerAddOnce(() => {
        vars.current.mainNav.height =
          getElBounds($mainNavEl.current)?.height || 0;
        vars.current.banner.height =
          getElBounds($bannerEl.current)?.height || 0;

        setNavigationHeight(vars.current.mainNav.height);
        setScrollPaddingTop(vars.current.mainNav.height + getSpacer(54));
        const wh = windowHeight || 0;
        vars.current.banner.triggerStart = $bannerEl.current ? wh / 4 : 0;
        vars.current.banner.triggerEnd =
          vars.current.banner.triggerStart + vars.current.banner.height;
        vars.current.mainNav.triggerStart = wh;
        vars.current.mainNav.triggerEnd = wh + vars.current.mainNav.height;
        vars.current.mainNav.distanceToBanner =
          vars.current.mainNav.triggerStart - vars.current.banner.triggerEnd;

        vars.current.maxScrollUpDiff = wh / 3;
        if (vars.current.banner.height)
          setBannerAnimation(vars.current.banner.height);
      }, false);
    }
  }, [
    windowWidth,
    windowHeight,
    setNavigationHeight,
    $el,
    $bannerEl,
    $mainNavEl,
    setBannerAnimation,
    setScrollPaddingTop,
  ]);

  useEffect(() => {
    // if banner is hidden, reset vars
    if (bannerDismissed) {
      vars.current.banner.height = 0;
      vars.current.banner.triggerStart = 0;
      vars.current.banner.triggerEnd = 0;

      vars.current.mainNav.distanceToBanner =
        vars.current.mainNav.triggerStart - vars.current.banner.triggerEnd;
    }
  }, [bannerDismissed]);

  // on load and route change figure out if we are currently on a subnav page
  useEffect(() => {
    setActiveMainNavItemToCurrentPageImmediately();
    setCurrentGroup(null);

    navigationData?.primaryLinks?.forEach((item: NavigationNavChildProps) => {
      if (item._type === 'navGroup') {
        const currentPath = pathname?.split('/')[1] || '';
        const isCurrentGroup = item.groupItemLinks.includes(currentPath);
        if (isCurrentGroup) {
          if (item._key) {
            setCurrentGroup(item._key);
            return;
          }
        }
      }
    });
  }, [
    navigationData,
    pathname,
    setCurrentGroup,
    setActiveMainNavItemToCurrentPageImmediately,
  ]);

  useEffect(() => {
    // clear timeout of resetting link active state highlight
    clearMainNavActiveStateDelay();
  }, [pathname, clearMainNavActiveStateDelay]);

  // Effect to set mobile
  useEffect(() => {
    // TODO: using window.innerWidth until the ui store's window width matches window innerwidth instead of document.body width
    // fixes issue with desktop subnav dropdown not working between 1240 and 1255
    if (navShowDesktopBktp && window.innerWidth >= navShowDesktopBktp) {
      setIsMobileNav(false);
    } else {
      setIsMobileNav(true);
    }
  }, [windowWidth, setIsMobileNav, navShowDesktopBktp]);

  return [
    // state
    {
      activeMainNavItem,
      currentOpenedSubNav,
      isHoveringMainNavItem,
      hasBlur: vars.current.hasBlur,
    },
    // methods
    {
      dismissBanner,
      onMouseOverMainNavItem,
      onMouseOutMainNavItem,
      clearMainNavActiveStateDelay,
      onClickMainNavGroup,
    },
    //   https://fettblog.eu/typescript-react-typeing-custom-hooks/
  ] as const;
};

export default useNavBar;
