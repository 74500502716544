import noop from 'lodash/noop';
import { usePathname } from 'next/navigation';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import DriftButton from '~/components/atoms/Buttons/Ctas/DriftButton/DriftButton';
import Link from '~/components/atoms/Link/Link';
import { cn } from '~/utils';

import useNavState from '../Navigation.state';
import styles from './NavItem.module.css';
import { NavItemProps } from './NavItem.types';

const NavItem = (
  {
    id,
    link,
    icon,
    label,
    driftButton,
    isGroupHeader = false,
    className,
    innerClassName,
    hidden,
    isActive,
    isReducedNav,
    onMouseOver = noop,
    onMouseOut = noop,
    onFocus = noop,
    onBlur = noop,
    onClick = noop,
  }: NavItemProps,
  ref: ForwardedRef<HTMLButtonElement>,
) => {
  const pathname = usePathname();
  const isCurrentPage = useMemo(
    () => link && pathname === link.url,
    [pathname, link],
  );

  const [isMobileNav] = useNavState((state) => [state.isMobileNav], shallow);

  const innerContent = (
    <span className={cn(styles.innerWrapper, innerClassName)}>
      {link?.label ? link.label : label ? label : ''}
      {icon && icon}
    </span>
  );

  const getLinkData = () => {
    if (!isGroupHeader) return link;

    // if item is a group header, it will be a clickable link on desktop and a button that expands the subnav on mobile
    if (isGroupHeader && !isMobileNav) return link;

    return undefined;
  };

  const classNames = cn(
    styles.navItem,
    isReducedNav && styles.isReducedNavNavItem,
    className,
    isActive && 'active',
  );

  if (driftButton) {
    return (
      <DriftButton
        {...driftButton}
        buttonVariant="text"
        className={classNames}
      />
    );
  }

  const linkData = getLinkData();
  if (linkData) {
    return (
      // disabling eslint here because we need the anchor to have mouseover and mouseout functionality to trigger the submenu
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        className={classNames}
        aria-current={isCurrentPage ? 'page' : false}
        onMouseOver={() => onMouseOver(id)}
        onMouseOut={onMouseOut}
        onFocus={() => onFocus(id)}
        onBlur={onBlur}
        tabIndex={hidden ? -1 : 0}
        aria-hidden={hidden}
        to={linkData}
        ref={ref}
      >
        {innerContent}
      </Link>
    );
  } else {
    return (
      <button
        className={classNames}
        aria-current={isCurrentPage ? 'page' : false}
        onMouseOver={() => onMouseOver(id)}
        onMouseOut={onMouseOut}
        onFocus={() => onFocus(id)}
        onBlur={onBlur}
        tabIndex={hidden ? -1 : 0}
        aria-hidden={hidden}
        onClick={() => onClick(id)}
        ref={ref}
      >
        {innerContent}
      </button>
    );
  }
};

export default forwardRef(NavItem);
