import { gsap } from 'gsap';
import { useCallback, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import useUIStore from '~/state/ui';
import { killTimeline } from '~/utils';

import { dismissBannerAnimation } from '../Navigation.animations';
import useNavState from '../Navigation.state';

const useBanner = () => {
  const [$el, $bannerEl] = useNavState(
    (state) => [state.$el, state.$bannerEl],
    shallow,
  );
  const setBannerDismissed = useUIStore((state) => state.setBannerDismissed);
  const setBannerHeight = useUIStore((state) => state.setBannerHeight);
  const bannerDismissed = useUIStore((state) => state.bannerDismissed);

  const bannerAnimation = useRef<GSAPTimeline>();

  const onBannerDismissed = useCallback(() => {
    setBannerDismissed(true);
    if (bannerAnimation.current) killTimeline(bannerAnimation.current);
  }, [setBannerDismissed]);

  const setBannerAnimation = useCallback(
    (height: number) => {
      if ($el && $bannerEl) {
        bannerAnimation.current = dismissBannerAnimation({
          $el,
          $bannerEl,
          bannerHeight: height,
          onComplete: onBannerDismissed,
        });

        gsap.set($el.current, {
          '--banner-height': `${height}px`,
        });

        setBannerHeight(height);
      }
    },
    [$el, $bannerEl, setBannerHeight, onBannerDismissed],
  );

  const dismissBanner = () => {
    if ($bannerEl?.current) bannerAnimation.current?.play();
  };

  return { bannerDismissed, setBannerAnimation, dismissBanner };
};

export default useBanner;
