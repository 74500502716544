'use client';
import { gsap } from 'gsap';
import { debounce } from 'lodash';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { RefObject, useCallback, useEffect, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import SvgFrameLogo from '~/assets/svg/logo-frame.svg';
import AdobeLogo from '~/components/atoms/AdobeLogo/AdobeLogo';
import Button from '~/components/atoms/Buttons/Ctas/Button/Button';
import AnnouncementBanner from '~/components/molecules/AnnouncementBanner/AnnouncementBanner';
import { dict } from '~/data/stores/Dictionary';
import useUIStore from '~/state/ui';
import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';
import ScrollPosition from '~/utils/domEvents/ScrollPosition/ScrollPosition';
import useFocusTrap from '~/utils/useFocusTrap';

import NavGroup from './NavGroup/NavGroup';
import { ForwardedNavGroupRef } from './NavGroup/NavGroup.types';
import styles from './Navigation.module.css';
import useNavState from './Navigation.state';
import { NavigationNavChildProps, NavigationProps } from './Navigation.types';
import NavItem from './NavItem/NavItem';
import NavMobile from './NavMobile/NavMobile';
import useNavBar from './utils/useNavBar';

const NavigationClient = ({
  announcementBanner,
  navigation,
  isReducedNav,
  accentColor,
}: NavigationProps) => {
  const $el = useRef<HTMLDivElement>(null);
  const $mainNavEl = useRef<HTMLDivElement>(null);
  const $bannerEl = useRef<HTMLDivElement>(null);
  const $mainNavCtaItems = useRef<RefObject<HTMLElement>[]>([]);
  const $backdropEl = useRef<HTMLDivElement>(null);
  const $shadow = useRef<HTMLDivElement>(null);
  const navGroups = useRef<Record<string, ForwardedNavGroupRef>>({});
  const mobileNavGroups = useRef<Record<string, ForwardedNavGroupRef>>({});
  const tallestSubNavWrapperHeight = useRef(0);
  const scrollYStart = useRef(0);

  const isNavigationOpaque = useUIStore((state) => state.isNavigationOpaque);

  const isNavigationDisabled = useUIStore(
    (state) => state.isNavigationDisabled,
  );

  const bannerDismissed = useUIStore((state) => state.bannerDismissed);

  const isNavigationBarVisible = useUIStore(
    (state) => state.isNavigationBarVisible,
  );

  const onFocusTrapKeyDownDesktop = useFocusTrap($mainNavEl);

  const [
    setCurrentOpenedSubNav,
    setNavigationData,
    setIsReducedNav,
    setEl,
    setMainNavEl,
    setBannerEl,
    setMaskEl,
    setShadowEl,
    setMobileMainNavCtaItems,
    isMobileNav,
  ] = useNavState(
    (state) => [
      state.setCurrentOpenedSubNav,
      state.setNavigationData,
      state.setIsReducedNav,
      state.setEl,
      state.setMainNavEl,
      state.setBannerEl,
      state.setMaskEl,
      state.setShadowEl,
      state.setMobileMainNavCtaItems,
      state.isMobileNav,
    ],
    shallow,
  );

  const [
    { activeMainNavItem, currentOpenedSubNav },
    {
      dismissBanner,
      onMouseOverMainNavItem,
      onMouseOutMainNavItem,
      onClickMainNavGroup,
    },
  ] = useNavBar({ navGroups, mobileNavGroups });

  const pathname = usePathname();

  // on mount, store the navigation data and shared refs (needed for animations) in the nav store
  useEffect(() => {
    setNavigationData(navigation);
    setIsReducedNav(isReducedNav);
    setEl($el);
    setMainNavEl($mainNavEl);
    setBannerEl($bannerEl);
    setMaskEl($backdropEl);
    setShadowEl($shadow);
    setMobileMainNavCtaItems($mainNavCtaItems);
  }, [
    isReducedNav,
    navigation,
    setBannerEl,
    setEl,
    setIsReducedNav,
    setMainNavEl,
    setMobileMainNavCtaItems,
    setNavigationData,
    setMaskEl,
    setShadowEl,
  ]);

  // subscribe to scroll position to close subnav on scroll
  useEffect(() => {
    if (currentOpenedSubNav && !isMobileNav) {
      scrollYStart.current = ScrollPosition.y || 0;

      const unsubscribe = ScrollPosition.subscribe(({ y: scrollY }) => {
        if (scrollY && scrollYStart.current >= 0) {
          const scrollDiff = scrollY - scrollYStart.current;

          if (Math.abs(scrollDiff) > 50) {
            navGroups.current[currentOpenedSubNav]?.closeSubNav();
          }
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [currentOpenedSubNav, isMobileNav]);

  const getTallestSubNavWrapperHeight = useCallback(() => {
    if (!isMobileNav) {
      for (const [, group] of Object.entries(navGroups.current)) {
        const height =
          group.$subNavWrapper.current?.getBoundingClientRect().height;

        if (height && height > tallestSubNavWrapperHeight.current)
          tallestSubNavWrapperHeight.current = height;
      }
    }

    gsap.set($mainNavEl.current, {
      '--subnav-height': `${tallestSubNavWrapperHeight.current}px`,
    });
  }, [isMobileNav, navGroups]);

  useEffect(() => {
    getTallestSubNavWrapperHeight();
    const unsubscribe = useUIStore.subscribe(
      (state) => state.windowWidth,
      debounce(() => {
        tallestSubNavWrapperHeight.current = 0;
        getTallestSubNavWrapperHeight();
      }, 100),
    );

    return () => {
      unsubscribe();
    };
  }, [getTallestSubNavWrapperHeight]);

  // on route change, close the subnavs
  useEffect(() => {
    setCurrentOpenedSubNav(null);
  }, [pathname, setCurrentOpenedSubNav]);

  const onClickBackgroundDim = () => {
    if (currentOpenedSubNav)
      navGroups.current[currentOpenedSubNav]?.closeSubNav();
  };

  const handleKeyDownOnWindow = useCallback(
    (event: globalThis.KeyboardEvent) => {
      if (event.code === 'Escape') {
        if (currentOpenedSubNav)
          navGroups.current[currentOpenedSubNav]?.closeSubNav();
      }

      if (onFocusTrapKeyDownDesktop && !isMobileNav)
        onFocusTrapKeyDownDesktop(event);
    },
    [currentOpenedSubNav, onFocusTrapKeyDownDesktop, isMobileNav],
  );

  useEffect(() => {
    if (currentOpenedSubNav) {
      window.addEventListener('keydown', handleKeyDownOnWindow);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDownOnWindow);
    };
  }, [currentOpenedSubNav, handleKeyDownOnWindow]);

  if (isNavigationDisabled) return null;

  const renderNavChild = ({
    button,
    renderedInMobileNav = false,
    index,
  }: {
    button: NavigationNavChildProps;
    renderedInMobileNav?: boolean;
    index?: number;
  }) => {
    const { _type, _key } = button;
    const id = _key || '';

    switch (_type) {
      case 'link':
        return (
          <li key={id}>
            <NavItem
              id={id}
              className={styles.navigationMainItem}
              link={button}
              onMouseOver={onMouseOverMainNavItem}
              onMouseOut={() => onMouseOutMainNavItem(true)}
              onFocus={onMouseOverMainNavItem}
              onBlur={() => onMouseOutMainNavItem(true)}
              ref={(element) => {
                if (renderedInMobileNav)
                  addToRefArray({ element, refArray: $mainNavCtaItems, index });
              }}
            />
          </li>
        );
      case 'button':
        const { to } = button;

        return (
          <li key={id}>
            <Button
              to={to}
              className={styles.navigationButton}
              buttonVariant="primary"
              buttonColorScheme="white"
              ref={(element) => {
                if (renderedInMobileNav)
                  addToRefArray({ element, refArray: $mainNavCtaItems, index });
              }}
            >
              {to?.label}
            </Button>
          </li>
        );
      case 'navGroup':
        return (
          <li key={id}>
            <NavGroup
              id={id}
              navGroup={button}
              isActive={activeMainNavItem === id}
              onMouseOver={onMouseOverMainNavItem}
              onMouseOut={onMouseOutMainNavItem}
              onClick={(id) => onClickMainNavGroup(id)}
              isReducedNav={isReducedNav}
              renderedInMobileNav={renderedInMobileNav}
              tallestSubNavWrapperHeight={tallestSubNavWrapperHeight}
              ref={(navGroup) => {
                if (navGroup) {
                  if (renderedInMobileNav) {
                    addToRefArray({
                      element: navGroup.$groupTriggerEl,
                      refArray: $mainNavCtaItems,
                      index,
                    });
                    mobileNavGroups.current[id] = navGroup;
                  } else {
                    navGroups.current[id] = navGroup;
                  }
                }
              }}
            />
          </li>
        );
      default:
        return null;
    }
  };

  const { appLinks } = navigation;
  const { signupLink, reducedNavLink, freeTrialLink, contactSalesButton } =
    appLinks;

  return (
    <header
      ref={$el}
      className={cn(styles.navigation)}
      aria-label={navigation.title}
    >
      <div
        className={cn(
          styles.navigationWrapper,
          isReducedNav && styles.isReducedNav,
          // Dictate navbar visibility independently from the useNavScroll hook
          !isNavigationBarVisible && styles.isHidden,
        )}
      >
        {announcementBanner && !bannerDismissed && (
          <AnnouncementBanner
            ref={$bannerEl}
            content={announcementBanner?.content}
            onClose={dismissBanner}
            className={styles.navigationBannerEl}
            accentColor={accentColor}
          />
        )}
        <nav
          className={cn(
            styles.mainNavigation,
            currentOpenedSubNav !== null && !isReducedNav && styles.subNavOpen,
            isNavigationOpaque && styles.isNavigationOpaque,
          )}
          ref={$mainNavEl}
        >
          <div className={styles.navigationInner}>
            <div className={styles.left}>
              <Link
                href="/"
                className={styles.logoLeft}
                title={dict('navLinkHome')}
              >
                <SvgFrameLogo title="Frame.io Logo" className={styles.logo} />
              </Link>
            </div>

            <NavMobile
              isHidden={isReducedNav}
              signupLink={signupLink}
              freeTrialLink={freeTrialLink}
              contactSalesButton={contactSalesButton}
              navGroups={mobileNavGroups}
            >
              {navigation.primaryLinks.map((button, index) => {
                return renderNavChild({
                  button,
                  renderedInMobileNav: true,
                  index,
                });
              })}
            </NavMobile>

            <ul
              className={cn(
                styles.desktopNavigation,
                isReducedNav && styles.isHidden,
              )}
            >
              {navigation.primaryLinks.map((button) => {
                return renderNavChild({
                  button,
                });
              })}
            </ul>

            <ul className={styles.right}>
              {isReducedNav ? (
                <>
                  <li>
                    <NavItem
                      id="signup"
                      link={reducedNavLink}
                      onMouseOver={onMouseOverMainNavItem}
                      onMouseOut={onMouseOutMainNavItem}
                      onFocus={onMouseOverMainNavItem}
                      onBlur={onMouseOutMainNavItem}
                      isActive={activeMainNavItem === 'signup'}
                      isReducedNav={true}
                      className={styles.navigationMainItem}
                    />
                  </li>
                  <li>
                    <Button
                      to={signupLink}
                      className={cn(styles.navigationButton)}
                      buttonVariant="primary"
                      buttonColorScheme="glass"
                    >
                      {signupLink?.label}
                    </Button>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    {/* drift button */}
                    <NavItem
                      id="contactSales"
                      driftButton={contactSalesButton.button}
                      className={styles.navigationMainItem}
                    />
                  </li>
                  <li>
                    <NavItem
                      id="signup"
                      link={signupLink}
                      className={styles.navigationMainItem}
                    />
                  </li>
                  <li>
                    <Button
                      to={freeTrialLink}
                      className={styles.navigationButton}
                      buttonVariant="primary"
                      buttonColorScheme="white"
                    >
                      {freeTrialLink.label}
                    </Button>
                  </li>
                </>
              )}

              <li className={styles.adobeLogo}>
                <AdobeLogo />
              </li>
            </ul>
          </div>

          <div className={styles.backdropSubnavContainer} ref={$backdropEl}>
            <figure className={cn(styles.backdrop, styles.backdropSubnav)} />
            <div ref={$shadow} className={styles.shadow}></div>
          </div>

          <div
            className={styles.backgroundDim}
            onClick={onClickBackgroundDim}
            role="presentation"
          ></div>

          {/* shown on mobile only */}
          <figure className={cn(styles.backdrop, styles.backdropMainNav)} />
        </nav>
      </div>
    </header>
  );
};

export default NavigationClient;
