import { BaseButtonProps } from '~/components/atoms/Buttons/Ctas/ButtonBase.types';
import { CMSDriftButtonProps } from '~/components/atoms/Buttons/Ctas/DriftButton/DriftButton.types';
import { CMSGraphicProps } from '~/components/atoms/Graphic/Graphic.types';
import { CMSLinkProps } from '~/components/atoms/Link/Link.types';
import { CMSAnnouncementBanner } from '~/components/molecules/AnnouncementBanner/AnnouncementBanner.types';
import { Breakpoint } from '~/types';
import { getNumberFromString } from '~/utils/Strings';

import navGroupStyles from './NavGroup/NavGroup.module.css';
import { CMSNavGroupLink } from './NavGroup/NavGroup.types';

export const RESET_ACTIVE_DELAY = 100;
export const HIDE_DELAY = 250;
// desktop durations
export const OPEN_DURATION = 0.65;
export const SWAP_DURATION = 0.45;
export const MIN_ROWS = getNumberFromString(navGroupStyles.minRows) || 1;
export const START_Y = 200;

export const OPEN_DURATION_MOBILE = 0.5;
export const NAV_ITEMS_DURATION_BASE = 0.5;
export const SUB_NAV_ITEMS_DURATION_BASE = 0.5;
export const MOBILE_NAV_ITEM_DURATION_MULTIPLIER = 0.05;
export const MOBILE_SUB_NAV_ITEM_DURATION_MULTIPLIER = 0.02;
// start position, before mobile nav is open
export const MOBILE_MAIN_NAV_ITEM_START = '50%';
// position when subnav is open
export const MOBILE_MAIN_NAV_ITEM_END = '-120%';
// start position, before subnav is open
export const MOBILE_SUB_NAV_ITEM_START = '85%';

export type CMSModuleNavigationProps = {
  announcementBanner: CMSAnnouncementBanner;
  navigation: NavBarNavigationProps;
  hasReducedNav: boolean;
  accentColor?: {
    color: string;
    prefersDarkText: boolean;
  };
};

export type NavigationProps = {
  announcementBanner: CMSAnnouncementBanner;
  navigation: NavBarNavigationProps;
  isReducedNav: boolean;
  accentColor?: {
    color: string;
    prefersDarkText: boolean;
  };
};

export type AppLinks = {
  signupLink: CMSLinkProps;
  freeTrialLink: CMSLinkProps;
  reducedNavLink: CMSLinkProps;
  contactSalesButton: { button: CMSDriftButtonProps };
};

export type NavigationNavChildProps =
  | CMSNavGroupLink
  | CMSLinkProps
  | BaseButtonProps;

export type CMSNavigationNavChildrenProps = NavigationNavChildProps[];

export type NavBarNavigationProps = {
  title: string;
  primaryLinks: CMSNavigationNavChildrenProps;
  logoLeft: {
    logo: CMSGraphicProps;
    link: CMSLinkProps;
  };
  mobileNavBottomLinks: NavigationNavChildProps[];
  appLinks: AppLinks;
};

export type NavigationState = {
  breakpoint: Breakpoint | null;
  mainNav: {
    triggerStart: number;
    triggerEnd: number;
    height: number;
    distanceToBanner: number;
  };

  banner: {
    triggerStart: number;
    triggerEnd: number;
    height: number;
  };
  // how many pixels scrolling back up to trigger nav to reappear
  maxScrollUpDiff: number;
  navOffset: number;
  hasBlur: boolean;
};
