import { gsap } from 'gsap';
import { noop } from 'lodash';
import { RefObject } from 'react';

import { EaseType } from '~/utils/singletons/Easing';

import {
  MIN_ROWS,
  OPEN_DURATION,
  START_Y,
  SWAP_DURATION,
} from './Navigation.types';

// reusable timeline to reveal headers, links and eyebrows
const reveal = ({
  $headers,
  $eyebrows,
  $listItems,
  duration = OPEN_DURATION * 0.8,
  paused = false,
}: {
  $headers: RefObject<HTMLElement>;
  $eyebrows: RefObject<HTMLElement[]>;
  $listItems: RefObject<HTMLElement[][]>;
  duration?: number;
  paused?: boolean;
}) => {
  const tl = gsap.timeline({ paused });
  tl.addLabel('start');

  // group header
  tl.fromTo(
    $headers.current,
    {
      yPercent: -80,
    },
    {
      yPercent: 0,
      duration: duration,
      ease: EaseType.BASIC_BUTTER,
    },
    'start',
  );

  // subcategory header/eyebrow
  tl.fromTo(
    $eyebrows.current,
    {
      yPercent: -350,
    },
    {
      yPercent: 0,
      duration: duration,
      ease: EaseType.BASIC_BUTTER,
    },
    'start',
  );

  // link list
  $listItems.current?.forEach((group) => {
    for (let itemIndex = 0; itemIndex < group.length; itemIndex++) {
      const startY = gsap.utils.mapRange(
        0,
        MIN_ROWS - 1,
        -START_Y * 0.5,
        -START_Y,
        itemIndex % MIN_ROWS,
      );

      tl.fromTo(
        group[itemIndex],
        {
          yPercent: startY,
        },
        {
          yPercent: 0,
          duration: duration,
          ease: EaseType.BASIC_BUTTER,
        },
        'start',
      );
    }
  });

  // fade in with delay
  tl.fromTo(
    [$listItems.current, $headers.current, $eyebrows.current],
    {
      opacity: 0,
    },
    {
      opacity: 1,
      delay: 0.15,
      duration: duration * 0.85,
      ease: EaseType.BASIC_BUTTER,
    },
    'start',
  );

  return tl;
};

// reusable timeline to hide headers, links and eyebrows
const hide = ({
  $headers,
  $eyebrows,
  $listItems,
  direction = 1,
  duration = OPEN_DURATION * 0.8,
}: {
  $headers: RefObject<HTMLElement>;
  $eyebrows: RefObject<HTMLElement[]>;
  $listItems: RefObject<HTMLElement[][]>;
  direction?: 1 | -1;
  duration?: number;
}) => {
  const tl = gsap.timeline();
  tl.addLabel('start');

  tl.fromTo(
    $headers.current,
    {
      yPercent: 0,
    },
    {
      yPercent: direction * 20,
      duration: duration,
      ease: EaseType.BASIC_BUTTER,
    },
    'start',
  );

  tl.fromTo(
    $eyebrows.current,
    {
      yPercent: 0,
    },
    {
      yPercent: direction * 100,
      duration: duration,
      ease: EaseType.BASIC_BUTTER,
    },
    'start',
  );

  $listItems.current?.forEach((group) => {
    for (let itemIndex = 0; itemIndex < group.length; itemIndex++) {
      const endY = gsap.utils.mapRange(
        0,
        MIN_ROWS - 1,
        START_Y * 0.5,
        START_Y,
        itemIndex % MIN_ROWS,
      );
      tl.fromTo(
        group[itemIndex],
        {
          yPercent: 0,
        },
        {
          yPercent: direction * endY,
          duration: duration,
          ease: EaseType.BASIC_BUTTER,
        },
        'start',
      );
    }
  });

  tl.fromTo(
    [$listItems.current, $headers.current, $eyebrows.current],
    {
      opacity: 1,
    },
    {
      opacity: 0,
      duration: duration * 0.8,
      ease: EaseType.BASIC_BUTTER,
    },
    'start',
  );

  return tl;
};

export const openDesktopNavSubNavAnimation = ({
  $desktopSubNavLinkContainers,
  $subCategoryEyebrows,
  $categoryHeader,
  $backdropEl,
  $shadowEl,
  subNavHeight,
  onComplete = noop,
}: {
  $categoryHeader: RefObject<HTMLHeadingElement>;
  $subCategoryEyebrows: RefObject<HTMLElement[]>;
  $desktopSubNavLinkContainers: RefObject<HTMLElement[][]>;
  $backdropEl: RefObject<HTMLDivElement>;
  $shadowEl: RefObject<HTMLDivElement>;
  subNavHeight: RefObject<number>;
  onComplete?: () => void;
}) => {
  const tl = gsap.timeline({
    paused: true,

    onComplete,
  });

  const subNavReaveal = reveal({
    $eyebrows: $subCategoryEyebrows,
    $headers: $categoryHeader,
    $listItems: $desktopSubNavLinkContainers,
    duration: undefined,
  });

  tl.addLabel('start');

  tl.fromTo(
    [$backdropEl.current],
    { height: 0 },
    {
      height: subNavHeight.current || 0,
      duration: OPEN_DURATION,
      ease: EaseType.BASIC_BUTTER,
    },
    'start',
  );

  if (subNavHeight.current)
    tl.fromTo(
      $shadowEl.current,
      { y: 0, opacity: 0 },
      {
        y: subNavHeight.current,
        opacity: 1,
        duration: OPEN_DURATION,
        ease: EaseType.BASIC_BUTTER,
      },
      'start',
    );

  tl.add(subNavReaveal, '<20%');

  return {
    navReveal: tl,
    subNavReaveal: subNavReaveal,
  };
};

export const closeDesktopNavSubNavAnimation = ({
  $desktopSubNavLinkContainers,
  $subCategoryEyebrows,
  $categoryHeader,
  $backdropEl,
  $shadowEl,
  subNavHeight,
  onComplete = noop,
}: {
  $categoryHeader: RefObject<HTMLHeadingElement>;
  $subCategoryEyebrows: RefObject<HTMLElement[]>;
  $desktopSubNavLinkContainers: RefObject<HTMLElement[][]>;
  $backdropEl: RefObject<HTMLDivElement>;
  $shadowEl: RefObject<HTMLDivElement>;
  subNavHeight: RefObject<number>;
  onComplete?: () => void;
}) => {
  const tl = gsap.timeline({
    paused: true,
    onComplete: onComplete,
  });

  tl.addLabel('start');

  tl.add(
    openDesktopNavSubNavAnimation({
      $desktopSubNavLinkContainers,
      $subCategoryEyebrows,
      $categoryHeader,
      $backdropEl,
      $shadowEl,
      subNavHeight,
    }).navReveal.reverse(),
  );

  return tl;
};

export const revealDesktopLinksAnimation = ({
  $desktopSubNavLinkContainers,
  $subCategoryEyebrows,
  $categoryHeader,
  onComplete = noop,
}: {
  $categoryHeader: RefObject<HTMLHeadingElement>;
  $subCategoryEyebrows: RefObject<HTMLElement[]>;
  $desktopSubNavLinkContainers: RefObject<HTMLElement[][]>;
  onComplete?: () => void;
}) => {
  const tl = gsap.timeline({
    paused: true,
    onComplete: onComplete,
  });

  tl.addLabel('start');

  tl.add(
    reveal({
      $eyebrows: $subCategoryEyebrows,
      $headers: $categoryHeader,
      $listItems: $desktopSubNavLinkContainers,
      duration: SWAP_DURATION,
    }),
    'start',
  );
  return tl;
};

export const hideDesktopLinksAnimation = ({
  $desktopSubNavLinkContainers,
  $subCategoryEyebrows,
  $categoryHeader,
  direction = 1,
  onComplete = noop,
}: {
  $desktopSubNavLinkContainers: RefObject<HTMLElement[][]>;
  $categoryHeader: RefObject<HTMLHeadingElement>;
  $subCategoryEyebrows: RefObject<HTMLElement[]>;
  direction: 1 | -1;
  onComplete?: () => void;
}) => {
  const tl = gsap.timeline({
    paused: true,

    onComplete: onComplete,
  });

  tl.addLabel('start');

  tl.add(
    hide({
      $eyebrows: $subCategoryEyebrows,
      $headers: $categoryHeader,
      $listItems: $desktopSubNavLinkContainers,
      direction,
      duration: SWAP_DURATION,
    }),
    'start',
  );
  return tl;
};

export const dismissBannerAnimation = ({
  $el,
  $bannerEl,
  bannerHeight,
  onComplete,
}: {
  $el: RefObject<HTMLDivElement>;
  $bannerEl: RefObject<HTMLDivElement>;
  bannerHeight: number;
  onComplete: () => void;
}) => {
  const tl = gsap.timeline({
    paused: true,
    onComplete: onComplete,
  });

  tl.to($el.current, {
    y: `-${bannerHeight}px`,
    duration: 0.5,
    ease: EaseType.BASIC_BUTTER,
  });

  tl.to(
    $bannerEl.current,
    {
      opacity: 0.25,
      duration: 0.25,
      ease: EaseType.BASIC_BUTTER,
    },
    `-=.35`,
  );

  return tl;
};
