import { gsap } from 'gsap';
import { noop } from 'lodash';
import { RefObject } from 'react';

import { EaseType } from '~/utils/singletons/Easing';

import {
  MOBILE_MAIN_NAV_ITEM_END,
  MOBILE_MAIN_NAV_ITEM_START,
  MOBILE_NAV_ITEM_DURATION_MULTIPLIER,
  MOBILE_SUB_NAV_ITEM_DURATION_MULTIPLIER,
  MOBILE_SUB_NAV_ITEM_START,
  NAV_ITEMS_DURATION_BASE,
  OPEN_DURATION_MOBILE,
  SUB_NAV_ITEMS_DURATION_BASE,
} from '../Navigation.types';

const getCtaItems = (items: RefObject<RefObject<HTMLElement>[]>) => {
  if (items.current) {
    return items.current.map((item) => item.current || item);
  }
  return [];
};

export const openMobileNavAnimation = ({
  $wrapper,
  $mobileMainNavCtaItems,
  $bottomLinksWrapper,
  onComplete = noop,
}: {
  $wrapper: RefObject<HTMLDivElement>;
  $mobileMainNavCtaItems: RefObject<RefObject<HTMLElement>[]>;
  $bottomLinksWrapper: RefObject<HTMLDivElement>;
  onComplete?: () => void;
}) => {
  const tl = gsap.timeline({
    paused: true,
    onComplete,
  });

  tl.addLabel('start');

  gsap.set($bottomLinksWrapper.current, {
    y: '0%',
  });
  gsap.set($wrapper.current, { visibility: 'visible' });

  // animate the blurred background in
  tl.fromTo(
    $wrapper.current,
    {
      x: '100%',
    },
    {
      x: '0%',
      duration: OPEN_DURATION_MOBILE,
      ease: EaseType.DEFAULT,
    },
    `start`,
  );

  const ctaItems = getCtaItems($mobileMainNavCtaItems);
  tl.fromTo(
    [ctaItems, $bottomLinksWrapper.current],
    {
      x: MOBILE_MAIN_NAV_ITEM_START,
      opacity: 0,
    },
    {
      x: '0%',
      opacity: 1,
      duration: function (i) {
        return (
          NAV_ITEMS_DURATION_BASE + MOBILE_NAV_ITEM_DURATION_MULTIPLIER * i
        );
      },
      ease: EaseType.BASIC_BUTTER,
    },
    `start`,
  );

  return tl;
};

export const closeMobileNavAnimation = ({
  $wrapper,
  onComplete = noop,
}: {
  $wrapper: RefObject<HTMLDivElement>;
  $mobileMainNavCtaItems: RefObject<RefObject<HTMLElement>[]>;
  onComplete?: () => void;
}) => {
  const tl = gsap.timeline({ paused: true, onComplete });
  tl.addLabel('start');

  tl.to(
    $wrapper.current,
    {
      x: '100%',
      duration: OPEN_DURATION_MOBILE,
      ease: EaseType.DEFAULT,
      onComplete: () => {
        gsap.set($wrapper.current, { visibility: 'hidden' });
      },
    },
    `start`,
  );

  return tl;
};

export const openMobileNavSubNavAnimation = ({
  $mobileSubNavLinks,
  $mobileBottomLinksWrapper,
  $mobileMainNavCtaItems,
  onComplete = noop,
}: {
  $mobileSubNavLinks: RefObject<HTMLElement[]>;
  $mobileMainNavCtaItems: RefObject<RefObject<HTMLElement>[]>;
  $mobileBottomLinksWrapper: RefObject<HTMLDivElement>;
  onComplete?: () => void;
}) => {
  const tl = gsap.timeline({
    paused: true,
    onComplete: onComplete,
  });

  tl.addLabel('start');

  tl.to(
    $mobileBottomLinksWrapper.current,
    {
      y: '300%',
      duration: OPEN_DURATION_MOBILE,
      ease: EaseType.BASIC_BUTTER,
    },
    'start',
  );

  const ctaItems = getCtaItems($mobileMainNavCtaItems);
  tl.fromTo(
    ctaItems,
    {
      x: '0%',
      opacity: 1,
    },
    {
      x: MOBILE_MAIN_NAV_ITEM_END,
      opacity: 0,
      duration: function (i) {
        return (
          SUB_NAV_ITEMS_DURATION_BASE +
          MOBILE_SUB_NAV_ITEM_DURATION_MULTIPLIER * i
        );
      },
      ease: EaseType.BASIC_BUTTER,
    },
    'start',
  );

  if ($mobileSubNavLinks.current?.length) {
    tl.fromTo(
      $mobileSubNavLinks.current,
      {
        x: MOBILE_SUB_NAV_ITEM_START,
        opacity: 0,
      },
      {
        x: '0%',
        opacity: 1,
        duration: function (i) {
          return (
            SUB_NAV_ITEMS_DURATION_BASE +
            MOBILE_SUB_NAV_ITEM_DURATION_MULTIPLIER * i
          );
        },
        ease: EaseType.BASIC_BUTTER,
      },
      'start',
    );
  }

  return tl;
};

export const closeMobileNavSubNavAnimation = ({
  $mobileSubNavLinks,
  $mobileBottomLinksWrapper,
  $mobileMainNavCtaItems,
  onComplete = noop,
}: {
  $mobileSubNavLinks: RefObject<HTMLElement[]>;
  $mobileMainNavCtaItems: RefObject<RefObject<HTMLElement>[]>;
  $mobileBottomLinksWrapper: RefObject<HTMLDivElement>;
  onComplete?: () => void;
}) => {
  const tl = gsap.timeline({
    paused: true,
    onComplete: onComplete,
  });

  tl.to(
    $mobileBottomLinksWrapper.current,
    {
      y: '0%',
      duration: OPEN_DURATION_MOBILE,
      ease: EaseType.BASIC_BUTTER,
    },
    'start',
  );

  const ctaItems = getCtaItems($mobileMainNavCtaItems);
  const total = ctaItems.length;

  tl.to(
    ctaItems,
    {
      x: '0%',
      opacity: 1,
      duration: function (i) {
        return (
          SUB_NAV_ITEMS_DURATION_BASE +
          MOBILE_SUB_NAV_ITEM_DURATION_MULTIPLIER * (total - (i + 1))
        );
      },
      ease: EaseType.BASIC_BUTTER,
    },
    'start',
  );

  if ($mobileSubNavLinks.current?.length) {
    tl.to(
      $mobileSubNavLinks.current,

      {
        x: MOBILE_SUB_NAV_ITEM_START,
        opacity: 0,
        duration: function (i) {
          return (
            SUB_NAV_ITEMS_DURATION_BASE +
            MOBILE_SUB_NAV_ITEM_DURATION_MULTIPLIER * (total - (i + 1))
          );
        },
        ease: EaseType.BASIC_BUTTER,
      },
      'start',
    );
  }

  return tl;
};
