'use client';
import { CSSProperties, ForwardedRef, forwardRef } from 'react';

import SvgCloseIcon from '~/assets/svg/close-icon.svg';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import { dict } from '~/data/stores/Dictionary';
import { cn } from '~/utils';

import styles from './AnnouncementBanner.module.css';
import { AnnouncementBannerProps } from './AnnouncementBanner.types';

const AnnouncementBanner = (
  { content, onClose, className, accentColor }: AnnouncementBannerProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <div
      className={cn(
        styles.announcementBanner,
        className,
        accentColor?.prefersDarkText && styles.prefersDarkText,
      )}
      ref={ref}
      style={
        accentColor
          ? ({
              '--color-accent-banner': `var(${accentColor.color})`,
            } as CSSProperties)
          : {}
      }
    >
      <TextLockup
        className={styles.content}
        lockupOptions={content.lockupOptions}
        value={content.blocks}
      />

      <button
        className={styles.closeButton}
        aria-label={dict('clickToClose')}
        onClick={onClose}
      >
        <SvgCloseIcon className={styles.closeButtonInner} />
      </button>
    </div>
  );
};

export default forwardRef<HTMLDivElement, AnnouncementBannerProps>(
  AnnouncementBanner,
);
