'use client';
import { gsap } from 'gsap';
import { useEffect, useRef } from 'react';

import { dict } from '~/data/stores/Dictionary';
import addToRefArray from '~/utils/addToRefArray';
import { EaseType } from '~/utils/singletons/Easing';

import useNavState from '../Navigation.state';
import styles from './ToggleButton.module.css';

type Props = {
  onClick: () => void;
};

export const ToggleButton = ({ onClick }: Props) => {
  const lines = Array(...Array(4));
  const $btnEl = useRef<HTMLButtonElement>(null);
  const $lines = useRef<HTMLSpanElement[]>([]);
  const $lineEls = useRef<HTMLSpanElement[]>([]);
  const mobileNavOpen = useNavState((state) => state.mobileNavOpen);
  const rotation = useRef(0);

  const duration = mobileNavOpen ? 0.55 : 0.4;
  useEffect(() => {
    // Make sure previous tweens are paused
    gsap.killTweensOf([$btnEl.current, $lines.current, $lineEls.current]);

    rotation.current += 90;
    gsap.to($btnEl.current, {
      duration: duration,
      rotate: `${rotation.current}deg`,
      ease: EaseType.EMBELLISHMENT,
    });

    gsap.to($btnEl.current, {
      duration: duration / 2,
      scale: 0.85,
      ease: EaseType.EMBELLISHMENT,
    });

    gsap.to($btnEl.current, {
      duration: duration / 2,
      scale: 1,
      delay: duration / 2,
      ease: EaseType.EMBELLISHMENT,
    });

    // ideally scale prop should be animated here, but need to use width/height in order to preserve border-radius
    gsap.to($lines.current, {
      duration: duration,
      width: mobileNavOpen ? '2px' : '4px',
      height: '24px',
      ease: EaseType.BASIC_BUTTER,
    });

    gsap.to($lineEls.current, {
      duration: duration,
      height: mobileNavOpen ? '24px' : '4px',
      ease: EaseType.BASIC_BUTTER,
    });
  }, [mobileNavOpen, duration]);

  return (
    <button
      ref={$btnEl}
      className={styles.navMobileButton}
      onClick={onClick}
      aria-label={dict('clickToToggleMobileNav')}
    >
      {lines.map((el, index) => {
        return (
          <span
            key={`toggle-btn-${index}`}
            className={styles.navMobileButtonLine}
            ref={(element) =>
              addToRefArray({ element, refArray: $lines, index })
            }
          >
            <span
              ref={(element) =>
                addToRefArray({ element, refArray: $lineEls, index })
              }
              className={styles.navMobileButtonLineEl}
            ></span>
          </span>
        );
      })}
    </button>
  );
};
