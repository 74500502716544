import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import useDelayedAction from '~/utils/useDelayedAction';

import useNavState from '../Navigation.state';
import { RESET_ACTIVE_DELAY } from '../Navigation.types';
const useMainNavActiveItem = () => {
  const [currentPageMainNavId, setActiveMainNavItem, setHoveredMainNavItem] =
    useNavState(
      (state) => [
        state.currentPageMainNavId,
        state.setActiveMainNavItem,
        state.setIsHoveringMainNavItem,
      ],
      shallow,
    );
  // setup timeout for the active state to come back after hovering out
  const [setMainNavActiveStateDelay, clearMainNavActiveStateDelay] =
    useDelayedAction(RESET_ACTIVE_DELAY);

  const [setMainNavHoveredStateDelay, clearMainNavHoveredStateDelay] =
    useDelayedAction(RESET_ACTIVE_DELAY);

  // set main active state to current page immediatly
  const setActiveMainNavItemToCurrentPageImmediately = useCallback(() => {
    clearMainNavActiveStateDelay();
    setActiveMainNavItem(currentPageMainNavId);
  }, [
    clearMainNavActiveStateDelay,
    setActiveMainNavItem,
    currentPageMainNavId,
  ]);

  // set main nav active item immediatly
  const setActiveMainNavItemImmediately = useCallback(
    (id: string) => {
      clearMainNavActiveStateDelay();
      setActiveMainNavItem(id);
    },
    [clearMainNavActiveStateDelay, setActiveMainNavItem],
  );

  // set main nav active item with delay
  const setActiveMainNavItemWithDelay = useCallback(
    (id: string) => {
      clearMainNavActiveStateDelay();
      setMainNavActiveStateDelay(() => {
        setActiveMainNavItem(id);
      });
    },
    [
      clearMainNavActiveStateDelay,
      setMainNavActiveStateDelay,
      setActiveMainNavItem,
    ],
  );

  const setIsHoveringMainNavItemImmediately = useCallback(
    (value: boolean) => {
      clearMainNavHoveredStateDelay();
      setHoveredMainNavItem(value);
    },
    [clearMainNavHoveredStateDelay, setHoveredMainNavItem],
  );

  const setIsHoveringMainNavItemWithDelay = useCallback(
    (value: boolean) => {
      clearMainNavHoveredStateDelay();
      setMainNavHoveredStateDelay(() => {
        setHoveredMainNavItem(value);
      });
    },
    [
      clearMainNavHoveredStateDelay,
      setHoveredMainNavItem,
      setMainNavHoveredStateDelay,
    ],
  );

  return {
    setActiveMainNavItemWithDelay,
    setActiveMainNavItemImmediately,
    setActiveMainNavItemToCurrentPageImmediately,
    setIsHoveringMainNavItemImmediately,
    setIsHoveringMainNavItemWithDelay,
    clearMainNavActiveStateDelay,
  };
};

export default useMainNavActiveItem;
